/* styles.css */
body {
    font-family: 'Bebas Neue', sans-serif; /* Use Bebas Neue font */
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
  }
  
  .container {
    max-width: 1000px;
    margin: auto;
  }
  
  h1 {
    color: var(--heading-color);
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .list-group-item {
    background-color: var(--item-background-color);
    border: 1px solid var(--item-border-color);
  }
  
  :root {
    --background-color: #181818;
    --text-color: #ffffff;
    --heading-color: #f8f9fa;
    --item-background-color: #343a40;
    --item-border-color: #4a4a4a;
  }
.logo {
    font-family: 'Impact', sans-serif; /* Use Bebas Neue font */
    font-size: 2.5rem; /* Increase font size */
    color: #ff4a63; /* Set color to a vibrant hue */
    letter-spacing: 2px; /* Add letter spacing for better readability */
    margin-bottom: 20px; /* Add some spacing below the logo */
  }
  
  .lowercase {
    text-transform: lowercase; /* Convert text to lowercase */
  }

    /* Center the logo */
    img.logo {
      display: block;
      margin: 50px auto 0;
    }
    /* Make the logo smaller */
    img.logo {
      width: 100px; /* Adjust the width as needed */
      height: auto;
    }

  
    .links {
      margin-top: 25px;
      text-align: center;
      display: flex;
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
    }
    .links a {
      color: #ff4a63; /* Grey color */
      text-decoration: none; /* Remove underline */
      margin-right: 10px; /* Add some space between links */
    }
    .links a:last-child {
      margin-right: 0; /* Remove margin for the last link */
    }
    .links a:hover,
    .links a:focus {
      text-decoration: none; /* Remove underline on hover and focus */
    }
    .links .separator {
      margin: 0 5px; /* Adjust spacing */
    }
    